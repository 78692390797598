import React from 'react'

import { Button, IconButton, Tooltip } from '@mui/material'

import { AddButton } from './add-button'
import { RenderProps } from './types'

import { EditIcon, PlusIcon } from '../../icons'

import { assertUnreachable } from '../../../utils/assert-unreachable'

type FormButtonType = 'action' | 'add' | 'disabled' | 'edit' | 'new' | 'plus' | 'textPlus'

export type FormButtonProps = {
    buttonTitle: string
    buttonType: FormButtonType
}

type FormDrawerButtonProps = Pick<RenderProps, 'toggleOpen'> & FormButtonProps

export const FormButton: React.FC<FormDrawerButtonProps> = ({ buttonTitle, buttonType, toggleOpen }) => {
    switch (buttonType) {
        case 'action':
            return (
                <Button variant="outlined" color="secondary" onClick={toggleOpen}>
                    {buttonTitle}
                </Button>
            )
        case 'add':
            return <AddButton onClick={toggleOpen} title={buttonTitle} />
        case 'textPlus':
            return (
                <Button onClick={toggleOpen} color="secondary" endIcon={<PlusIcon color="secondary" />}>
                    {buttonTitle}
                </Button>
            )
        case 'disabled':
            return (
                <Tooltip title={buttonTitle} placement="left">
                    <IconButton disableRipple aria-disabled="true" aria-label={buttonTitle} sx={{ cursor: 'default' }}>
                        <EditIcon fontSize="small" sx={(theme) => ({ color: theme.palette.dark.lighter })} />
                    </IconButton>
                </Tooltip>
            )
        case 'edit':
            return (
                <IconButton onClick={toggleOpen} aria-label={buttonTitle}>
                    <EditIcon fontSize="small" sx={{ color: '#0D0D0D' }} />
                </IconButton>
            )
        case 'new':
            return (
                <Button variant="contained" onClick={toggleOpen}>
                    {buttonTitle}
                </Button>
            )
        case 'plus':
            return (
                <IconButton onClick={toggleOpen} size="small" aria-label={buttonTitle}>
                    <PlusIcon />
                </IconButton>
            )

        default:
            return assertUnreachable(buttonType)
    }
}

import { FormProps } from 'react-final-form'

import { useTranslation } from '@/lib/i18n'
import { Button, SxProps } from '@mui/material'

import { RenderProps } from './types'

import { Footer } from '../../layout/drawer/footer'

type FormFooterProps = Pick<FormProps, 'formId' | 'confirmLabel'> &
    Pick<RenderProps, 'toggleOpen' | 'submitting'> & {
        backgroundColor?: string
        sx?: SxProps
    }

export const FormFooter: React.FC<FormFooterProps> = ({ formId, submitting, toggleOpen, confirmLabel, backgroundColor, sx }) => {
    const { t } = useTranslation('common')

    return (
        <Footer backgroundColor={backgroundColor} sx={sx}>
            <Button variant="contained" type="submit" form={formId} disabled={submitting}>
                {confirmLabel || t('save')}
            </Button>
            <Button variant="outlined" color="secondary" onClick={toggleOpen} disabled={submitting}>
                {t('cancel')}
            </Button>
        </Footer>
    )
}

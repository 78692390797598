'use client'

import { Box, SxProps } from '@mui/material'

import { palette } from '../../../theme/palette'

export const Header: React.FC<{ children: React.ReactNode; backgroundColor?: string; sx?: SxProps }> = ({
    backgroundColor = palette.light.white,
    children,
    sx,
}) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        paddingX={3}
        paddingY={2}
        sx={{ backgroundColor, ...sx }}>
        {children}
    </Box>
)

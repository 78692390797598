import { Box, SxProps } from '@mui/material'

import { palette } from '../../../theme/palette'

export const Footer: React.FC<{ children: React.ReactNode; backgroundColor?: string; sx?: SxProps }> = ({
    backgroundColor = palette.light.lightShade,
    children,
    sx,
}) => (
    <Box display="flex" alignItems="center" gap={1} paddingX={3} paddingY={2} sx={{ backgroundColor, ...sx }}>
        {children}
    </Box>
)

import { Box, Divider, Drawer, DrawerProps } from '@mui/material'

import { FormButton } from './form-button'
import { FormFooter } from './form-footer'
import { FormHeader } from './form-header'
import { FormProps } from './types'
import { useModalState } from './use-modal-state'

import { palette } from '../../../theme/palette'

type FormDrawerProps = FormProps & {
    drawerProps?: Omit<DrawerProps, 'open'>
}

export const FormDrawer: React.FC<FormDrawerProps> = ({ buttonTitle, buttonType, children, confirmLabel, drawerProps, title, formId }) => {
    const { open, toggleOpen, submitting, setSubmitting } = useModalState()

    return (
        <>
            <FormButton buttonTitle={buttonTitle} buttonType={buttonType} toggleOpen={toggleOpen} />

            <Drawer
                anchor="right"
                open={open}
                onClose={toggleOpen}
                PaperProps={{
                    sx: {
                        background: palette.light.lightShade40,
                        width: {
                            md: 750,
                        },
                    },
                }}
                {...drawerProps}>
                <FormHeader title={title} toggleOpen={toggleOpen} />

                <Divider />

                <Box paddingX={3} paddingY={2} flex={1} overflow="auto">
                    {children({ toggleOpen, submitting, setSubmitting })}
                </Box>

                <FormFooter formId={formId} submitting={submitting} toggleOpen={toggleOpen} confirmLabel={confirmLabel} />
            </Drawer>
        </>
    )
}

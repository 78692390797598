import { useState } from 'react'

export const useModalState = () => {
    const [open, setOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const toggleOpen = () => setOpen(!open)

    return {
        open,
        submitting,
        setSubmitting,
        toggleOpen,
    }
}

import { FormProps } from 'react-final-form'

import { Typography } from '@mui/material'

import { RenderProps } from './types'

import { ExitIcon } from '../../icons'
import { Header } from '../../layout/drawer/header'

type FormHeaderProps = Pick<FormProps, 'title'> & Partial<Pick<RenderProps, 'toggleOpen'>>

export const FormHeader: React.FC<FormHeaderProps> = ({ title, toggleOpen }) => {
    return (
        <Header>
            <Typography variant="h3" color="dark.simple">
                {title}
            </Typography>
            {toggleOpen ? <ExitIcon sx={{ fontSize: 18 }} cursor="pointer" onClick={toggleOpen} /> : null}
        </Header>
    )
}
